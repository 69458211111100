<template>
    <div>
        <div class="card border-0 rounded-lg overflow-hidden shadow-sm">
            <iframe class="w-100"
                    height="315"
                    v-bind:src="link"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "YoutubeCard",
    props: {
        link: {
            type: String,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
