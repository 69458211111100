<template>
    <div class="flip-countdown">
        <div ref="flip"
             class="d-flex flex-wrap"
             data-repeat="true"
             data-layout="horizontal fit"
             data-transform="preset(d, h, m, s) -> delay">
            <div class="tick-group shadow-sm px-2">
                <div data-key="value"
                     data-repeat="true"
                     data-transform="pad(00) -> split -> delay">
                    <span data-view="flip"></span>
                </div>
                <span data-key="label"
                      data-view="text"
                      class="tick-label">
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

const locale = {
    DAY_PLURAL: "日",
    DAY_SINGULAR: "日",
    HOUR_PLURAL: "小時",
    HOUR_SINGULAR: "小時",
    MINUTE_PLURAL: "分鐘",
    MINUTE_SINGULAR: "分鐘",
    SECOND_PLURAL: "秒",
    SECOND_SINGULAR: "秒",
};

export default {
    name: "FlipCountdown",
    props: {
        date: {
            type: String,
        },
    },
    data () {
        return {
            initTime: Date.now(),

            tickInstance: null,
            counterInstance: null,
        };
    },
    computed: {

    },
    methods: {

    },
    async mounted () {
        if (this.date) {
            this.counterInstance = Tick.count.down(
                this.date,
                {
                    "server": true,
                }
            );
            this.tickInstance = Tick.DOM.create(
                this.$refs.flip,
                {
                    didInit: (tick) => {
                        for (const key in locale) {
                            if (locale[key]) {
                                tick.setConstant(key, locale[key]);
                            }
                        }
                        this.counterInstance.onupdate = (value) => {
                            tick.value = value;
                        };
                    }
                }
            );
            this.counterInstance.onended = () => {
                this.$emit("ended");
            };
        }

    },
    destroyed () {
        Tick.DOM.destroy(this.$refs.flip);
    }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.tick {
    font-size: 1rem !important;
}

.tick-flip,
.tick-text-inline {
    font-weight: bolder;
    font-size: 2.5rem;

    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 4rem;
    }
}

.tick-text-inline {
    display: inline-block;
    text-align: center;
    min-width: 1rem;
    color: #595d63 !important;

    & + .tick-text-inline {
        margin-left: -0.325rem;
    }
}

.tick-label {
    margin-top: 1rem;
    font-size: 1rem;
    color: #ffffff !important;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }
}

.tick-char {
    width: 1.5rem;
}

.tick-group {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;

    @include media-breakpoint-up(md) {
        flex: 0 0 25%;
        max-width: 25%;
    }

    margin-bottom: 1rem;
    text-align: center;
    white-space: nowrap;

    &:first-child {
        flex: 0 0 100%;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

.tick-flip-panel {
    color: #fff !important;
    background-color: #3c3e3c !important;
}

.tick-flip-panel-text-wrapper {
    line-height: 1.45 !important;
}

.tick-flip {
    border-radius: 0.5rem;

    @include media-breakpoint-up(md) {
        border-radius: 1rem;
    }
}
</style>
