<template>
    <div class="coming-soon position-relative">
        <grain-filter></grain-filter>
        <div class="position-relative container">
            <h1 class="d-none d-md-block text-center font-weight-bolder text-white mb-4">
                距離放映會還有：
            </h1>
            <h3 class="d-md-none text-center font-weight-bolder text-white mb-4">
                距離放映會還有：
            </h3>

            <flip-countdown class="d-flex justify-content-center mb-2"
                            date="2021-02-14T04:00:00.000+00:00"
                            v-on:ended="timesUp">
            </flip-countdown>

            <h5 class="d-none d-md-block text-center text-white mb-5">
                公元二〇二一年二月十四日星期日正午十二時正
            </h5>
            <p class="d-md-none text-center text-white mb-5">
                公元二〇二一年二月十四日星期日正午十二時正
            </p>

            <h4 class="d-none d-md-block text-white font-weight-bold mb-4">
                現在請先欣賞我們過往的表演片段：
            </h4>
            <h5 class="d-md-none text-white font-weight-bold mb-4">
                現在請先欣賞我們過往的表演片段：
            </h5>

            <div class="row">
                <youtube-card class="col-lg-6 mb-3"
                              link="https://www.youtube.com/embed/KAvBfRZsYxQ">
                </youtube-card>
                <youtube-card class="col-lg-6 mb-3"
                              link="https://www.youtube.com/embed/gqxRQLHiiGU">
                </youtube-card>
            </div>
        </div>
    </div>
</template>

<script>
import FlipCountdown from "../components/ComingSoon/FlipCountdown";
import YoutubeCard from "../components/ComingSoon/YoutubeCard";
import GrainFilter from "../components/ComingSoon/GrainFilter";

export default {
    name: "ComingSoon",
    components: {
        GrainFilter,
        YoutubeCard,
        FlipCountdown,
    },
    metaInfo: {
        title: "不日上映",
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {
        timesUp () {
            console.log("the time is now");
            this.$router.push("/watch");
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.coming-soon {
    background-color: rgba(17.5, 17.5, 17.5, 1);
    padding-top: 15vh;

    @include media-breakpoint-up(md) {
        padding-top: 25vh;
    }

    & > .container {
        z-index: 1;
    }
}
</style>
