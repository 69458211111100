<template>
    <div class="position-absolute grain-wrapper overflow-hidden">
        <div class="position-absolute grain-filter"></div>
    </div>
</template>

<script>
export default {
    name: "GrainFilter",
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.grain-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.grain-filter {
    top: -100%;
    left: -50%;

    height: 300%;
    width: 300%;

    opacity: 0.4;

    background-image: url(../../assets/img-noise-150x150.png);
    background-repeat: repeat;

    animation: grain 8000ms steps(10) infinite;
}

@keyframes grain {
    0%, 100% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(-5%, -10%);
    }

    20% {
        transform: translate(-15%, 5%);
    }

    30% {
        transform: translate(7%, -25%);
    }

    40% {
        transform: translate(-5%, 25%);
    }

    50% {
        transform: translate(-15%, 10%);
    }

    60% {
        transform: translate(15%, 0%);
    }

    70% {
        transform: translate(0%, 15%);
    }

    80% {
        transform: translate(3%, 35%);
    }

    90% {
        transform: translate(-10%, 10%);
    }
}
</style>
